// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-faq-js": () => import("/Users/kebert/Git/wedding-website/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-home-js": () => import("/Users/kebert/Git/wedding-website/src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("/Users/kebert/Git/wedding-website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rsvp-js": () => import("/Users/kebert/Git/wedding-website/src/pages/rsvp.js" /* webpackChunkName: "component---src-pages-rsvp-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/kebert/Git/wedding-website/.cache/data.json")

